import React from "react";
import { graphql, PageProps } from "gatsby";
import RibbonList from "../components/RibbonList";
import { AllRibbonQuery, SanityRibbon } from "../../graphql-types";
import ModelsFilter from "../components/ModelsFilter";
import SEO from "../components/SEO";

interface IRibbonsPage extends PageProps {
  data: AllRibbonQuery;
  pageContext: {
    model?: string;
  };
}

export default function RibbonsPage(props: IRibbonsPage) {
  const ribbons = props.data.ribbons.nodes;
  return (
    <>
      <SEO
        title={
          props.pageContext?.model
            ? `Lacinhos com ${props.pageContext?.model}`
            : "Todos os tipos de lacinhos"
        }
      />
      <ModelsFilter />
      <RibbonList ribbons={ribbons as SanityRibbon[]} />
    </>
  );
}

export const query = graphql`
  query AllRibbon($model: [String]) {
    ribbons: allSanityRibbon(
      filter: { models: { elemMatch: { name: { in: $model } } } }
    ) {
      nodes {
        name
        id
        price
        slug {
          current
        }
        models {
          name
          id
        }
        image {
          asset {
            fixed(width: 200, height: 200) {
              ...GatsbySanityImageFixed
            }
            fluid(maxHeight: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
