import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import { AllModelsQuery, SanityRibbon } from "../../graphql-types";
import styled from "styled-components";

const ModelsStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 4rem;
  a {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 0 1rem;
    align-items: center;
    padding: 0.5rem;
    background: var(--grey);
    border-radius: 2px;
    text-decoration: none;
    font-size: clamp(1.5rem, 1.4vw, 2.5rem);
    .count {
      background: white;
      padding: 2px 5px;
    }
    &[aria-current="page"] {
      background: var(--yellow);
    }
  }
`;

const MODELS_QUERY = graphql`
  query AllModels {
    models: allSanityModel {
      nodes {
        baby
        id
        name
      }
    }
    ribbons: allSanityRibbon {
      nodes {
        models {
          id
          name
        }
      }
    }
  }
`;

type ModelFilter = {
  id: string;
  name: string;
  count: number;
};

function countRibbonsInModels(ribbons: SanityRibbon[]): ModelFilter[] {
  const counts: ModelFilter[] = ribbons
    .map((ribbon) => ribbon.models)
    .flat()
    .reduce((acc, model) => {
      const existingModel = acc[model.id];
      if (existingModel) {
        existingModel.count += 1;
      } else {
        acc[model.id] = {
          id: model.id,
          name: model.name,
          count: 1,
        } as ModelFilter;
      }
      return acc;
    }, {} as ModelFilter);

  const sortedModels = Object.values(counts).sort((a, b) => b.count - a.count);
  return sortedModels;
}

export default function ModelsFilter() {
  const { models, ribbons } = useStaticQuery<AllModelsQuery>(MODELS_QUERY);

  const modelsWithCount = countRibbonsInModels(ribbons.nodes as SanityRibbon[]);

  return (
    <ModelsStyle>
      <Link to={`/ribbons`}>
        <span className="name">All</span>
        <span className="count">{ribbons.nodes.length}</span>
      </Link>
      {modelsWithCount.map((item) => (
        <Link key={item.id} to={`/model/${item.name}`}>
          <span className="name">{item.name}</span>
          <span className="count">{item.count}</span>
        </Link>
      ))}
    </ModelsStyle>
  );
}
