import React from "react";
import { SanityRibbon } from "../../graphql-types";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

const RibbonsGridList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 4rem;
  grid-auto-rows: auto auto 220px;
`;

const RibbonStyle = styled.div`
  display: grid;
  /* Take your row sizing not from the pizzaStyles div, but from the  PizzaGridStyles grid */
  @supports not (grid-template-rows: subgrid) {
    --rows: auto auto 1fr;
  }
  grid-template-rows: var(--rows, subgrid);
  grid-row: span 3;
  grid-gap: 1rem;
  h2,
  p {
    margin: 0;
  }
  img {
    height: auto;
    object-fit: contain;
  }
  @media (max-width: 600px) {
    .mark {
      font-size: 2.5rem;
    }
    .gatsby-image-wrapper {
      width: 80%;
    }
  }
`;

interface RibbonListProps {
  ribbons: SanityRibbon[];
}

interface SingleRibbonProps {
  ribbon: SanityRibbon;
}

function SingleRibbon({ ribbon }: SingleRibbonProps) {
  return (
    <RibbonStyle>
      <Link to={`/ribbon/${ribbon.slug.current}`}>
        <h2>
          <span className="mark">{ribbon.name}</span>
        </h2>
      </Link>
      <p>{ribbon.models.map((model) => model.name).join(", ")}</p>
      <Img fluid={ribbon.image.asset.fluid} alt={ribbon.name} />
    </RibbonStyle>
  );
}

export default function RibbonList({ ribbons }: RibbonListProps) {
  return (
    <RibbonsGridList>
      {ribbons.map((item) => (
        <SingleRibbon key={item.id} ribbon={item} />
      ))}
    </RibbonsGridList>
  );
}

export const ribbonListFragment = graphql`
  fragment Ribbon on SanityRibbon {
    name
    id
    price
    slug {
      current
    }
    models {
      name
      id
    }
    image {
      asset {
        fluid(maxHeight: 400) {
          ...GatsbySanityImageFluid
        }
      }
    }
  }
`;
